import React from "react";
import classes from "./Footer.module.css";

const date = new Date();
const year = date.getFullYear();

const footer = (props) => (
    <div className={classes.Footer}>
        <div className={classes.Copyright}>©Foxtrot Dog Walking. {year} All rights reserved.</div>
    </div>
);

export default footer;