import React from "react";
import styled from "styled-components";
import ItemsCarousel from "./src/ItemsCarousel/index";
import classes from "./Gallery.module.css";
import img1 from "../../assets/images/ben1.jpg";
import img2 from "../../assets/images/ben2.jpg";
import img3 from "../../assets/images/ben3.jpg";
import img4 from "../../assets/images/Ben4.jpg";
import img5 from "../../assets/images/Ben5.jpg";
import img6 from "../../assets/images/Ben6.jpg";

const noOfItems = 6;
const noOfCards = 3;
const autoPlayDelay = 2000;
const chevronWidth = 40;

const Wrapper = styled.div`
  padding: 0 ${chevronWidth}px;
  max-width: 1000px;
  margin: 0 auto;
`;

const SlideItem = styled.div`
  height: 350px;
  width: 300px;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  overflow: hidden;
`;



const carouselItems = [
<SlideItem className={classes.SlideItem}><img src={img1} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></SlideItem>,
<SlideItem className={classes.SlideItem}><img src={img2} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></SlideItem>,
<SlideItem className={classes.SlideItem}><img src={img3} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></SlideItem>,
<SlideItem className={classes.SlideItem}><img src={img4} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></SlideItem>,
<SlideItem className={classes.SlideItem}><img src={img5} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></SlideItem>,
<SlideItem className={classes.SlideItem}><img src={img6} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></SlideItem>];


export default class AutoPlayCarousel extends React.Component {
  state = {
    activeItemIndex: 0
  };

  componentDidMount() {
    this.interval = setInterval(this.tick, autoPlayDelay);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  tick = () =>
    this.setState(prevState => ({
      activeItemIndex:
        (prevState.activeItemIndex + 1) % (noOfItems - noOfCards + 1)
    }));

  onChange = value => this.setState({ activeItemIndex: value });

  render() {
    return (
      <section>
        <div id="gallery">
        <h2>Gallery</h2>
          <div className={classes.DesktopOnly}>
          <Wrapper>
            <ItemsCarousel
              gutter={12}
              numberOfCards={noOfCards}
              slidesToScroll={2}
              activeItemIndex={this.state.activeItemIndex}
              requestToChangeActive={this.onChange}
              rightChevron={<button>{">"}</button>}
              leftChevron={<button>{"<"}</button>}
              chevronWidth={chevronWidth}
              outsideChevron={false}
              children={carouselItems}
              showSlither={true}
              firstAndLastGutter={true}
            />
          </Wrapper>
          </div>
          <div className={classes.MobileOnly}>
          <div className={classes.MobileImage}><img src={img1} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></div>
          <div className={classes.MobileImage}><img src={img2} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></div>
          <div className={classes.MobileImage}><img src={img3} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></div>
          <div className={classes.MobileImage}><img src={img4} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></div>
          <div className={classes.MobileImage}><img src={img5} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></div>
          <div className={classes.MobileImage}><img src={img6} alt="Ben Foxtrot Dog Walking" height="auto" width="100%"/></div>
        </div>        
        </div>

      </section>
    );
  }
}
