import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';


import classes from './NavItem.module.css';

const navItem = ( props ) => (
    <li className={classes.NavItem}>
        <Link to={props.link}
        onClick={props.clicked}
        className={props.active ? classes.active : null}>{props.children}
        </Link>
    </li>
);

export default navItem;