import React from "react";
import classes from "./TextOnly.module.css";
import dogBone from "../../assets/images/dogBoneBlue.png"


const textOnly = props => (
  <section>
    <div className={classes.TextOnlyContainer}>
        <div className={classes.Test}>
        <img src={dogBone} alt="Happy Dog" />
    <hr />
        </div>

      <div className={classes.TextOnlyInner} id="about">
        <h2>About Foxtrot Dogwalking</h2>
        <p>
        After more than a decade of walking dogs, we here at Foxtrot Dog Walking are comfortable working with dogs of all shapes, sizes, attitudes, and breeds. We have an extremely flexible schedule and are happy to walk your dog morning, noon, and night. 
        </p>
        <p>
        While your dog is in our care, we will be sure to; provide them with stimulating and fun exercise, make sure they have plenty of food and water, administer oral and topical medication when necessary* Give them the care, attention, and affection we would give our own dogs.
        </p>
        <p>
        We are all dog owners and because of that have an understanding of how important a role our canine friends play in our lives and our families. And because we also love our city we want to make sure New Orleans is full of happy, healthy dogs and dog-owning families.
        </p>
      </div>
    </div>
  </section>
);

export default textOnly;
