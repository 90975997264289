import React from 'react';

import classes from './Toolbar.module.css';
import NavigationItems from '../NavigationItems/NavigationItems';
import Toggle from '../MobileNavigation/Toggle/Toggle'

const toolbar = ( props ) => (

    <header className={classes.Toolbar} id="navbar">
        <Toggle clicked={props.mobileToggleClicked} />
        <nav className={classes.DesktopOnly}>
            <NavigationItems />
        </nav>
    </header>
);

export default toolbar;