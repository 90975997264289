import React from "react";
import classes from "./Rates.module.css";

const rates = (props) => (
  <section>
    <div className={classes.TextOnlyContainer}>
      <div className={classes.Test}>
      </div>

      <div className={classes.TextOnlyInner} id="rates">
        <h2>Rates</h2>
        <table class="rates">
          <tbody>
            <tr>
              <th>Service</th>
              <th>Rate</th>
            </tr>
            <tr>
              <td>30 minute walk</td>
              <td>$22 ($28 for 2 dogs)</td>
            </tr>
            <tr>
              <td>1 hour walk</td>
              <td>$35 ($45 for 2 dogs)</td>
            </tr>
            <tr>
              <td>15 minute potty break</td>
              <td>$14 ($19 for 2 dogs)</td>
            </tr>
            <tr>
              <td>In home bath</td>
              <td>$15 per dog</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </section>
);

export default rates;
