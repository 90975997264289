import React from 'react';
import styled from 'styled-components';

import ActiveItem from '../../../../../ActiveItem/index'

import Content from '../Content';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Image = styled.div`
  width: 100%
  height: 100%;
  background-image: url(https://s3.us-east-2.amazonaws.com/foxtrotdogwalking/animal-canine-cute-160740.jpg);
  background-size: cover;
  background-position: center;
  `;
  
const forwardStyleImage = { transform: 'translateY(-100%)', opacity: 0};
const backwardStyleImage = { transform: 'translateY(100%)', opacity: 0};

const forwardStyleContent = { transform: 'translateX(100%)', opacity: 0};
const backwardStyleContent = { transform: 'translateX(-100%)', opacity: 0};

const WithImage = () => (
  <Wrapper>
    <ActiveItem
      forwardStyle={forwardStyleImage}
      backwardStyle={backwardStyleImage}
    >{
      style => (
        <Image style={style} />
      )
    }
    </ActiveItem>

    <ActiveItem
      forwardStyle={forwardStyleContent}
      backwardStyle={backwardStyleContent}
    >{
      style => (
        <Content
          title={'"These guys are awesome!"'}
          style={style}
          description={"They're very reliable and flexible when need be. We've been using them for a few years now and they've made a significant difference in the quality of our Mr. Charlie's life. I'd recommend them to everyone!"}
        />
      )
    }
    </ActiveItem>
  </Wrapper>
);

export default WithImage;
