import React from "react";
import classes from "./Contact.module.css";
const contact = props => (
  <div id="contact">
       <div className={classes.Header}></div>
    <iframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSeSMmsHXTfykP7UcAnKxPu1el02KlKgwnv_yPsNEodL7lOFcw/viewform?embedded=true"
      width="100%"
      height="1267"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
    >
      Loading...
    </iframe>
 </div>
);
export default contact;
