import React from 'react';
import styled from 'styled-components';

import ActiveItem from '../../../../../ActiveItem/index'

import Content from '../Content';


const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(https://s3.us-east-2.amazonaws.com/foxtrotdogwalking/animal-animal-photography-animal-portrait-422220.jpg);
  background-size: cover;
  background-position: center;
  `;

const forwardStyleImage = { transform: 'translateX(-100%)', opacity: 0};
const backwardStyleImage = { transform: 'translateX(100%)', opacity: 0};

const forwardStyleContent = { transform: 'scale(.5) translateX(100%)', opacity: 0};
const backwardStyleContent = { transform: 'scale(2) translateY(100%)', opacity: 0};

const WithImage = () => (
  <Wrapper> 
    <ActiveItem
      forwardStyle={forwardStyleImage}
      backwardStyle={backwardStyleImage}
    >{
      style => (
        <Image id="spring" style={style}/>
      )
    }
    </ActiveItem>

    <ActiveItem
      forwardStyle={forwardStyleContent}
      backwardStyle={backwardStyleContent}
    >{
      style => (
        <Content
          title={'"Amazing Services!!!!"'}
          description={"Foxtrot Dog Walking is one of the most efficient and reliable experiences I've had with my Yorkie Baxtor . I now know he's in good hands while  I'm traveling. We recently had to have an emergency weekend sit and walk, Foxtrot took care of us, this is typically hard to find on such short notice. Keep this up, I'm definitely a new client."}
          style={style}
        />
      )
    }
    </ActiveItem>
  </Wrapper>
);

export default WithImage;
