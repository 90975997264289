import React from 'react';

import Carousel from '../../../Carousel/index';
import classes from './Basic.module.css';

import Winter from './Components/One';
import Autumn from './Components/Four';
import Summer from './Components/Three';
import Spring from './Components/Two';

const Slider = (props) => (
  <section className={classes.SliderContainer} id="clients">
      <Carousel>
 
    <Summer />
    <Winter />
    <Spring />
    <Autumn /> 


  </Carousel>
  </section>

);

export default Slider;
