import React from "react";
import classes from "./Hero.module.css";
import heroImg from "../../assets/images/foxtrot_hero.jpg";

const hero = props => (
  <section>
    <div id="home" className={classes.HeroImage}>
      <img src={heroImg} alt="Happy Dog" />
      <div className={classes.HeroText}>
        <h1>Foxtrot Dog Walking</h1>
        <h4>A New Orleans Dog Walking Company <span>&#x269c;</span></h4>
      </div>
    </div>
  </section>
);

export default hero;
