import React from 'react';

import NavigationItems from '../NavigationItems/NavigationItems';
import classes from './MobileNavigation.module.css';
import Aux from '../../../hoc/Aux/Aux';
import Backdrop from '../../UI/Backdrop/Backdrop'

const mobileNavigation = ( props ) => {
    let attachedClasses = [classes.SideDrawer, classes.Close];
    if (props.open) {
        attachedClasses = [classes.SideDrawer, classes.Open];
    }
    return (
        <Aux>
            <Backdrop show={props.open} clicked={props.closed}/>
            <div className={attachedClasses.join(' ')}>

                <nav>
                    <NavigationItems clicked={props.closed}/>
                </nav>
            </div>
        </Aux>
    );
};

export default mobileNavigation;