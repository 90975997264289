import React, { Component } from "react";
import Aux from "../../hoc/Aux/Aux";
import Hero from "../../components/Hero/Hero";
import TextOnly from "../../components/TextOnly/TextOnly";
import Slider from "../../components/Slider/Slides/Basic/Basic/index";
import Service from "../../components/Service/Service";
import Rates from "../../components/Rates/Rates";
import Gallery from "../../components/Gallery/Gallery";


class HomePage extends Component {
  render() {
    return (
      <div id="Home">
        <Aux>
          <Hero />
          <TextOnly />
          <Gallery /> 
          <Service />
          <Rates />
          <Slider />
        </Aux>
      </div>
    );
  }
}
export default HomePage;
