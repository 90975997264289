import React from 'react';

import classes from './NavigationItems.module.css';
import NavItem from './NavItem/NavItem';

const navigationItems = (props) => (
    <ul className={classes.NavigationItems} onClick={props.clicked}>
        <NavItem link="/#home" active>Home</NavItem>
        <NavItem link="/#about">About</NavItem>
        <NavItem link="/#gallery">Gallery</NavItem>
        <NavItem link="/#services">Services</NavItem>
        <NavItem link="/#rates">Rates</NavItem>
        <NavItem link="/#clients">Client Reviews</NavItem>
        <NavItem link="/contact">Contact</NavItem>
    </ul>
);

export default navigationItems;