import React from "react";
import styled from "styled-components";

import ActiveItem from "../../../../../ActiveItem/index";

import Content from "../Content";
//import imageUrl from 'https://s3.us-east-2.amazonaws.com/foxtrotdogwalking/adorable-animal-animal-photography-416255.jpg';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(https://s3.us-east-2.amazonaws.com/foxtrotdogwalking/adorable-animal-animal-photography-416255.jpg);
  background-size: cover;
  background-position: center;
`;

const forwardStyleImage = { transform: "translateX(-100%)", opacity: 0 };
const backwardStyleImage = { transform: "translateX(100%)", opacity: 0 };

const forwardStyleContent = { transform: "scale(.5)", opacity: 0 };
const backwardStyleContent = { transform: "scale(2)", opacity: 0 };

const WithImage = () => (
  <Wrapper>
    <ActiveItem
      forwardStyle={forwardStyleContent}
      backwardStyle={backwardStyleContent}
    >
      {style => (
        <Content
          title={'"Ben is a dog whisperer!"'}
          description={
            "Our dogs (an overactive 3 year old boxer and a 10 year old puggle) absolutely loved him and would get so excited whenever they sensed his presence. He has always been very friendly, professional, dependable and trustworthy. We are moving to California and are saddened that we won't be able to rely on his services anymore. Ben is the best!"
          }
          style={style}
        />
      )}
    </ActiveItem>

    <ActiveItem
      id="autumn"
      forwardStyle={forwardStyleImage}
      backwardStyle={backwardStyleImage}
    >
      {style => <Image style={style} />}
    </ActiveItem>
  </Wrapper>
);

export default WithImage;
