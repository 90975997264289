import React from 'react';
import styled from 'styled-components';

import ActiveItem from '../../../../../ActiveItem/index'

import Content from '../Content';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(https://s3.us-east-2.amazonaws.com/foxtrotdogwalking/animal-border-collie-dog-37860.jpg);
  background-size: cover;
  background-position: center;
`;

const forwardStyleImage = { transform: 'translateX(-100%)', opacity: 0};
const backwardStyleImage = { transform: 'translateX(100%)', opacity: 0};

const forwardStyleContent = { transform: 'scale(.5)', opacity: 0};
const backwardStyleContent = { transform: 'scale(2)', opacity: 0};

const WithImage = () => (
  <Wrapper>
    <ActiveItem
      forwardStyle={forwardStyleImage}
      backwardStyle={backwardStyleImage}
    >{
      style => (
        <Image id="winter" style={style} />
      )
    }
    </ActiveItem>

    <ActiveItem
      forwardStyle={forwardStyleContent}
      backwardStyle={backwardStyleContent}
    >{
      style => (
        <Content
          title={'"Foxtrot Dog Walking is the best in New Orleans"'}
          description={"I work full time and I go to school full time and a few months back had the cutest rescue puppy fall into my lap. I use to bike home from work everyday on my lunch break and by the time I got back to work it was over. I would have to rush home after work to let Cereal out and then rush to school hopefully making it on time. I'm so happy I met this guy! He's honest and trust worthy and he loves what he does and my little nugget Cereal absolutely loves him. I do not have to stress anymore on my lunch breaks or while I'm in class because I know my little baby is in excellent hands. He has also been great enough to help me out on extremely short notice weekend getaways. Even when things slow down for me he still calls or texts to make sure my baby is doing well. I have sent a couple people his way and they also only have great things to say (you might want to work on a client referral program.) I definitely recommend Foxtrot Dog Walking to anyone knowing that your dog is his number one priority."}
          style={style}
        />
      )
    }
    </ActiveItem>
  </Wrapper>
);

export default WithImage;
