import React from "react";
import classes from "./ServiceMod.module.css";

const serviceMod = props => (
    <div className={classes.ServiceMod}>
      <h3>{props.title}</h3>
      <img src={props.serviceIcon} className={classes.IconImg} alt="Service Icon" />
      <p>{props.text} </p>
    </div>
);

export default serviceMod;
