import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';

import Aux from '../Aux/Aux';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import MobileNavigation from '../../components/Navigation/MobileNavigation/MobileNavigation';
import classes from './Layout.module.css';
import HomePage from '../../containers/Home/Home';
import Contact from '../../components/Contact/Contact';
import Footer from '../../components/Footer/Footer';

class Layout extends Component {

    state = {
        showMobileNav: false,
        isTop: true
    }

    componentDidMount() {
        document.addEventListener('scroll', () => {
          const isTop = window.scrollY <= 50;
          const nav = document.querySelector('#navbar');
          if (isTop !== this.state.isTop) {
              this.setState({ isTop })
              nav.style = 'background-color: none';
          } else {
            nav.style = 'background-color: rgba(255,255,255,0.5); color: #fff';
          }
          if (window.innerWidth <= 499){
            nav.style = 'background-color: none';
          }
        });
      }
    mobileLinkClickedHandler = () => {
        this.setState({
            showMobileNav: false
        })
    }
    mobileNavClosedHandler = () => {
        this.setState({
            showMobileNav: false
        })
    }

    mobileNavToggleHandler = () => {
        this.setState((prevState) => {
            return {showMobileNav: !prevState.showMobileNav};
        })
    }

    render() {
        return ( 
            <Aux >
                <Toolbar mobileToggleClicked={this.mobileNavToggleHandler} className={this.state.isTop ? 'down' : 'up'} />
                <MobileNavigation
                    open={this.state.showMobileNav}
                    closed={this.mobileNavClosedHandler} />
                <main className={classes.Content}>
                    {this.props.children}
                </main>
                <Switch>
                    <Route path="/contact" exact render={() => <Contact />} />
                    <Route path="/" exact component={HomePage} /> 
                    <Route render={() => <h1>Page Not Found</h1>} />    
                </Switch>
                <Footer />
            </Aux>
        )
    }
}

export default Layout;